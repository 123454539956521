export const ROUTE_ERRORS = {
	/** A required token is missing. */
	missingToken: { message: 'Service unavailable', error: '1' },
	unauthorized: { message: 'Session not found', error: 'Unauthorized' },
	missingBody: { message: `'body' is missing`, error: 'MissingBody' },
	bodyNotAnArray: { message: `'body' is not an array`, error: 'BodyNotAnArray' },
	syncDisabled: { message: `Synchronisation is disabled`, error: 'SyncDisabled' }
} as const

export const isAuthenticated = (req: Request, token: string) => req.headers.get('authorization') !== `Bearer ${token}`

export const getBody = <TJsonBody = any>(req: Request): Promise<TJsonBody | null> => req.json().catch(() => null)

export const getStream = () => {
	let controller: ReadableStreamDefaultController

	const readable = new ReadableStream({
		start(_controller) {
			controller = _controller
		}
	})

	// @ts-expect-error `controller` is defined in ReadableStream constructor
	return { readable, controller }
}

export const respond = (controller: ReadableStreamDefaultController, line: unknown): null => {
	if (controller) {
		const encoder = new TextEncoder()
		controller.enqueue(encoder.encode(`${JSON.stringify(line)}'\n'`))
	}
	return null
}

export const formDataToObject = (formData: FormData) =>
	Array.from(formData.entries()).reduce((accumulator, [key, value]) => ({ ...accumulator, [key]: value }), {})
